import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout.tsx"
import FeedbackCard from "../../components/feedback_card.tsx"
import AboutUs from "../../components/about_us.tsx"

const GeoPassProject = ({ data, location }) => {
  return (
    <Layout location={location} title="GeoPass Project">
      <div className="geopass-project-hero" />
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:ml-12 -mt-24 ">
        <div className="absolute top-80 right-36 hidden xl:block">
          <FeedbackCard location={location} />
        </div>
        <h1>The Public Space Access Grid</h1>
        <p className="lead">
          Geo-tracking is already used to monitor people and create personas to
          market to them. But what if we actually used geo-tracking anonymously
          so that it doesn't invade the privacy of people yet can still provide
          benefits to the person and the communities they live in. This project
          aims to find a suitable privacy preserving technology and platform to
          enable decentralized location based products and services.
        </p>
      </div>
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-16">
        <h3>The Challenge</h3>
        <p className="mb-4">
          Currently geo-tracking is used by tech giants and large corporations
          for “your convenience”. Consumers give access to location data in
          their phones to use apps. Most of the time, this is done unknowingly.
          These data points are collected and used to build a profile on the
          consumer and track their behavior and movements. Some of it is
          convenient. Google maps can tell you if there’s an accident ahead and
          reroute you to a faster route. Weather apps can you flash flood or
          tornado warnings. Target and Walmart know when you are within 400
          meters of the store and can gather your pick-up items and have them
          ready outside as soon as you pull up. A lot of it is not for
          convenience, but to build a profile on you and sell off your
          information. This is a very profitable business and interestingly
          enough, the consumer gets zero in return for their own information.
        </p>
        <p className="mb-4">
          We also see this technology being very useful in local communities if
          done properly. Most communities have limited funding and antiquated
          methods for judging community needs. This leads to allocating their
          limited resources to a few projects of speculative interest all funded
          by tax payer money that do not know that this is where their money is
          going. We suspect that many community members would be happy to
          support community projects that match their interests and if their was
          a clear budget, transparent process, and roadmap or set expectations.
          There also could be a system for rewards given to the community
          members for participation in making the community better. We have
          segmented four different use cases for potential areas we believe this
          technology would be beneficial.
        </p>
      </div>
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-16 text-black">
        <h3>The Vision</h3>
        <p>
          Team Zero believes the path forward either pays the consumer for
          information, allows the consumer to control what types of data a
          service is allowed to have, can take back that information, or can
          simply stay anonymous throughout any experience. Team Zero is also
          looking into how this technology may keep consumers anonymous, but
          also reward them in their own communities for participating in local
          experience. We also have a vision that will allow more voice in how
          communities allocate money or operate. We are looking for more
          efficient ways to improve local communities and be able to gauge the
          effectiveness and satisfaction of its citizens. Here's a few examples
          of how this might works.
        </p>
      </div>
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-16 text-black">
        <h3>Public Parks</h3>
        <p>
          Enable city and local governments to manage access to public parks.
          This technology would allow the creation of PassZones in parks and
          nature preserves to limit the number of people, charge for access, or
          improve budgeting for resources. It could be setup for temporary
          events such as festivals and outdoor markets. It could also be setup
          for longer term for trails, fields, natural areas.
        </p>
      </div>
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-16 text-black">
        <h3>CommunityEngagement </h3>
        <p>
          "Your-Town-Here" rewards programs with Citizen Tokens! Setup rewards
          programs for their citizens to encourge usage of the parks,
          recreational areas, and community grounds that are paid for by tax
          dollars. “Citizen tokens” can be assigned to people who visit
          requently. Those tokens can be used to buy or use perks (e.g. free
          kayak rental after 10 visits to Lake Wallenpaupack).
        </p>
      </div>
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-16 text-black">
        <h3>Local Business</h3>
        <p>
          Local businesses can offer incentives based on GeoPass token holders
          and those with certain levels of “Citizen tokens”. This ties in
          revenue from local business (tax dollars) to local governments and
          creates a positive feedback loop for everyone involved. Better
          businesses, better revenue, better parks, happier citizens.
        </p>
      </div>
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-16 text-black">
        <h3>Construction</h3>
        <p>Site access management and tracking.</p>
      </div>
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-16 text-black">
        <h3>Have We Crossed Paths?</h3>
        <p>
          A bit more of a novelty use case, "Have We Crossed Paths" was based on
          an idea that the world is really smaller than we realize. We are
          simply not aware of each other in different stages of our lives. This
          doesn't mean we didn't share and enjoy the same experiences. It would
          be interesting to be able to allow access to 2 people's
          geo-coordinates throughout a lifetime and see if their positions were
          ever close to each other, where, and when.
        </p>
      </div>

      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-16">
        <h3>Our Approach</h3>
        <p>
          We're currently looking into each area, researching, contacting people
          in the industry, and performing small experiments. If you are
          interested in learning more about how we explore, please check this
          out (link to how we work)
        </p>
      </div>
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto my-16">
        <h3>Experiments</h3>
        <p>We have not performed any experiments yet for this project.</p>
      </div>
      <div
        id="form"
        className="m-4 lg:mr-20 grid grid-cols-1 lg:grid-cols-2 gap-4"
      >
        <div className="m-auto order-last mt-6 lg:order-first lg:mr-10 pb-4">
          <FeedbackCard location="projects/geopass" />
        </div>
        <div className="mt-4 ml-1 mr-10">
          <AboutUs />
        </div>
      </div>
    </Layout>
  )
}

export default GeoPassProject

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          tags
          project
        }
      }
    }
  }
`
